import React, { FC, useMemo } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { CheckboxGroup } from 'wix-ui-tpa/CheckboxGroup';
import { Checkbox } from 'wix-ui-tpa/Checkbox';

import { IWidgetProps } from '../../Widget/Widget.types';
import { ProductFacet } from '../ProductFacet';
import { classes } from './ProductFacetsCollections.st.css';
import { getVisibleCollections } from '../../../../../../lib/productFacets';

interface ProductFacetsCollectionsProps {
  collections: IWidgetProps['productFacets']['collections'];
  selectedCollections?: IWidgetProps['productFacets']['filter']['collections'];
  onFacetsFilterChange: IWidgetProps['onProductFacetsFilterChange'];
}

export const ProductFacetsCollections: FC<ProductFacetsCollectionsProps> = ({
  collections,
  selectedCollections = [],
  onFacetsFilterChange,
}) => {
  const { t } = useTranslation();

  const visibleCollections = useMemo(() => {
    return getVisibleCollections(collections, selectedCollections);
  }, [collections, selectedCollections]);

  const renderLabel = (title: string, count: number) => {
    return (
      <>
        {title} <span className={classes.count}>({count})</span>
      </>
    );
  };

  return (
    <ProductFacet title={t('searchResults.facets.collectionTitle')}>
      <div className={classes.root}>
        <CheckboxGroup>
          {visibleCollections.map(({ value, count }) => (
            <Checkbox
              className={classes.checkbox}
              withFocusRing
              label={renderLabel(value, count)}
              checked={selectedCollections.includes(value)}
              key={value}
              onChange={({ checked }) => {
                onFacetsFilterChange({
                  collections: checked
                    ? [...selectedCollections, value]
                    : [...selectedCollections.filter((v) => v !== value)],
                });
              }}
            />
          ))}
        </CheckboxGroup>
      </div>
    </ProductFacet>
  );
};
