import React, { FC, useState, useEffect } from 'react';
import { Range, RangeProps } from 'wix-ui-tpa/Range';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { IWidgetProps } from '../../Widget/Widget.types';
import { ProductFacet } from '../ProductFacet';

import { classes } from './ProductFacetsPrice.st.css';

interface ProductFacetsPriceProps {
  language: IWidgetProps['language'];
  currency: string;
  minPrice: number;
  maxPrice: number;
  selectedMinPrice?: number;
  selectedMaxPrice?: number;
  onFacetsFilterChange: IWidgetProps['onProductFacetsFilterChange'];
}

export const ProductFacetsPrice: FC<ProductFacetsPriceProps> = ({
  language,
  currency,
  minPrice,
  maxPrice,
  selectedMinPrice,
  selectedMaxPrice,
  onFacetsFilterChange,
}) => {
  const { t } = useTranslation();
  const min = Math.floor(minPrice);
  const max = Math.ceil(maxPrice);

  const [value, setValue] = useState<[number?, number?]>([
    selectedMinPrice,
    selectedMaxPrice,
  ]);

  useEffect(() => {
    setValue([selectedMinPrice, selectedMaxPrice]);
  }, [selectedMinPrice, selectedMaxPrice]);

  const rangeValue: RangeProps['value'] = [value[0] ?? min, value[1] ?? max];

  const handleChange: RangeProps['onChange'] = ([newMinPrice, newMaxPrice]) => {
    setValue([
      newMinPrice !== min ? newMinPrice : undefined,
      newMaxPrice !== max ? newMaxPrice : undefined,
    ]);
  };

  const handleAfterChange = () => {
    const [newMinPrice, newMaxPrice] = value;

    if (newMinPrice !== selectedMinPrice || newMaxPrice !== selectedMaxPrice) {
      onFacetsFilterChange({
        minPrice: newMinPrice,
        maxPrice: newMaxPrice,
      });
    }
  };

  return (
    <ProductFacet title={t('searchResults.facets.priceTitle')}>
      <Range
        className={classes.root}
        min={min}
        max={max}
        value={rangeValue}
        onChange={handleChange}
        onAfterChange={handleAfterChange}
        format={{
          style: 'currency',
          currency,
          locale: language,
        }}
      />
    </ProductFacet>
  );
};
